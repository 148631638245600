<script>
import AntInput from '@/components/AntInput.vue';
import moment from 'moment';

export default {
  components: { AntInput },
  props: {
    input: Object,
    disabled: Boolean,
  },
  emits: ['change'],
  data: () => ({
    date: '',
    formattedDate: '',
    menu: false,
  }),
  watch: {
    input: {
      immediate: true,
      handler(entry) {
        if (entry?.type === 'date') {
          if (entry?.value) {
            this.date = moment(entry?.value).format('YYYY-MM-DD');
          }
        }
      },
    },
    date: {
      immediate: true,
      handler(value) {
        if (value) {
          this.formattedDate = moment(value).format('DD-MM-YYYY');
        }
      },
    },
  },
};
</script>

<template>
  <ant-input
    :hint="input.hint"
    :is-optional="!input.required"
    :label="input.name.replace('_', ' ')"
    style="max-width: 300px"
  >
    <template #input-field>
      <v-text-field
        v-if="input.type === 'text'"
        :disabled="disabled"
        :placeholder="input.name.replace('_', ' ')"
        :prepend-inner-icon="input?.prefixIcon"
        :value="input.value"
        dense
        filled
        hide-details
        type="text"
        @change="(value) => $emit('change', value)"
      />

      <v-text-field
        v-else-if="input.type === 'float'"
        :disabled="disabled"
        :placeholder="input.name.replace('_', ' ')"
        :prepend-inner-icon="input?.prefixIcon"
        :value="input.value"
        dense
        filled
        hide-details
        type="number"
        @change="(value) => $emit('change', value)"
      />
      <v-text-field
        v-else-if="input.type === 'integer'"
        :disabled="disabled"
        :placeholder="input.name.replace('_', ' ')"
        :prepend-inner-icon="input?.prefixIcon"
        :value="input.value"
        dense
        filled
        hide-details
        type="number"
        @change="(value) => $emit('change', value)"
      />
      <v-checkbox
        v-else-if="input.type === 'boolean'"
        :disabled="disabled"
        :input-value="input.value"
        :placeholder="input.name.replace('_', ' ')"
        dense
        filled
        hide-details
        @change="(value) => $emit('change', value)"
      />

      <v-menu
        v-else-if="input.type === 'date'"
        v-model="menu"
        :close-on-content-click="false"
        :disabled="disabled"
        :nudge-right="40"
        min-width="auto"
        offset-y
        transition="scale-transition"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="formattedDate"
            :disabled="disabled"
            :placeholder="input.name.replace('_', ' ')"
            clearable
            dense
            filled
            hide-details
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="date"
          @input="
            (value) => {
              date = value;
              menu = false;
              $emit('change', value);
            }
          "
        />
      </v-menu>
      <v-select
        v-else-if="input.type === 'dropdown'"
        :items="input.options_value"
        :placeholder="input.name.replace('_', ' ')"
        :prepend-inner-icon="input?.prefixIcon"
        :value="input.value"
        dense
        filled
        hide-details
        @change="(value) => $emit('change', value)"
      />
    </template>
  </ant-input>
</template>

<style lang="scss" scoped></style>
